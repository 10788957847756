<template>
    <el-card class="v_store_list">
      <div class="g-flex" slot="header">
        <span>店铺列表</span>
        <div class="g-flex-justify-end g-flex-1" v-if="auth.bind">
          <el-button type="primary" @click="$refs.StoreListPop.onShow()">绑定店铺</el-button>
        </div>
      </div>
      <el-form :inline="true" size="mini" :model="query">
        <el-form-item label="店铺类型:">
          <el-select v-model="query.platform_id" placeholder="查询" @change="getList">
            <el-option label="全部" value="ALL"></el-option>
            <el-option v-for="(item) in shopTypeList" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="审核状态:">
          <el-select v-model="query.status" placeholder="查询" @change="getList">
            <el-option v-for="(item) in storePassList" :key="item.value" :label="item.title" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商家ID:">
           <el-input v-model="query.user_id" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
        </el-form-item>

        <el-form-item label="平台账号:">
           <el-input v-model="query.account" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
        </el-form-item>

        <el-form-item label="发货人手机号:">
          <el-input v-model="query.mobile" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
        </el-form-item>

        <el-form-item>
          <el-select slot="label" v-model="query.search_key" placeholder="查询">
            <el-option label="店铺名称" value="title"></el-option>
          </el-select>
          <el-row>
            <el-col :span="14">
              <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
            </el-col>
            <el-col :span="4" :offset="1">
              <el-button type="info" @click="resetData">重置</el-button>
            </el-col>
            <el-col :span="4" :offset="1">
              <el-button type="primary" @click="getList()">查询</el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <el-table stripe size="mini" v-loading="table.loading" :data="table.rows" border>
        <el-table-column prop="user_id" label="商家ID" width="80" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="id" label="店铺ID" width="80" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="platform_id" label="店铺类型" width="80" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span> {{ shopTypeList.find(item => { return scope.row.platform_id === item.id }).title }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="店铺名称" width="150" :show-overflow-tooltip="true"> </el-table-column>
         <el-table-column prop="flow_price" label="平台服务费溢价" width="120" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="url" label="店铺链接"  width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="account" label="平台账号" width="150" :show-overflow-tooltip="true"> </el-table-column>
        <el-table-column prop="name" label="发货人姓名" width="150" :show-overflow-tooltip="true"> </el-table-column>
        <el-table-column label="发货人手机号" width="150" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>{{scope.row.mobile}}</span>
            </template>
        </el-table-column>
        
        <el-table-column label="发货地址"  prop="region" width="240" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column label="状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span class="g-red" v-show="scope.row.status === 0">审核未通过 <span>({{scope.row.remark}})</span></span>
            <span class="g-yellow" v-show="scope.row.status === 1">审核中</span>
            <span class="g-green" v-show="scope.row.status === 2">审核通过</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm')}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="auth.pass ? 300: 150" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="passClick(scope.row)" v-if="auth.pass && scope.row.status === 1">审核</el-button>
            <el-button size="mini" type="primary" @click="serviceConfigClick(scope.row)" v-if="auth.pass">配置平台服务费</el-button>
            <el-button size="mini" type="warning" @click="setConfigPlatForm(scope.row)" v-if="auth.pass">配置佣金</el-button>
            <el-button size="mini" type="success" @click="setForm(scope.row)" v-if="auth.bind">修改店铺</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>

      <store-list-pop ref="StoreListPop" :shopTypeList="shopTypeList" @emitSaveSuccess="emitSaveSuccess"/>

      <store-list-config-pop ref="StoreListConfigPop"  :shopTypeList="shopTypeList"></store-list-config-pop>

      <store-list-pass-pop ref="StoreListPassPop" :shopTypeList="shopTypeList" @emitSaveSuccess="getList"/>

      <!-- 平台服务费配置 -->
      <store-list-config-service-pop ref="StoreListConfigServicePop"  @emitSaveSuccess="getList(false)"/>
    </el-card>
</template>

<script>
  import { apiGetShopTypeList, apiGetShopList } from '@/utils/api.js'
  import { formatDate } from '@/utils/index.js'
  import StoreListPop from './StoreListPop.vue'
  import StoreListConfigPop from './StoreListConfigPop.vue'
  import StoreListPassPop from './StoreListPassPop.vue'
  import StoreListConfigServicePop from './StoreListConfigServicePop.vue'
  export default {
  components: { StoreListPop, StoreListConfigPop, StoreListPassPop, StoreListConfigServicePop },
  name: 'RoleList',
  async created(){
    await this.apiGetShopTypeListHandel()
    await this.getList()
  },
  data() {
    return {
      shopTypeList: [],

      storePassList: [
        { title: '全部', value: 'ALL' },
        { title: '审核中', value: '1' },
        { title: '审核通过', value: '2' },
        { title: '审核不通过', value: '0' },
      ],

      auth: {
        pass: this.$global.ApiList.includes(13),
        bind: this.$global.ApiList.includes(31),
      },

      query: { search_key: 'title', search_val: '', status: this.$route.query.status ? '1' : 'ALL', user_id: '', platform_id: 'ALL', mobile: '', account: '', page:1, limit:15 },

      table:{ loading:false, total:0, rows:[] },

      form: false,

      formData: {},

      roleMenu:false,
      role_id:0,
      MenuList:[],
      
    }
  },
  methods: {
    formatDate,
    // 审核
    passClick(formData) {
      let copyFormData = JSON.parse(JSON.stringify(formData))
      this.$refs.StoreListPassPop.onShow(copyFormData)

    },

    // 平台服务费配置
    serviceConfigClick(formData) {
      this.$refs.StoreListConfigServicePop.onShow(formData)
      console.log(formData)
    },

    // 修改成功触发
    emitSaveSuccess() {
      this.getList()
    },

    // 获取平台类型
    async apiGetShopTypeListHandel() {
      const { success, data } = await apiGetShopTypeList()
      if(!success) return
      this.shopTypeList = data.list
      console.log(data)
    },

    //获取列表
    async getList(pageInit = true) {
      if (pageInit) this.query.page = 1
      this.table.loading = true
      const { success, data } = await apiGetShopList(this.query)
      if(!success) return this.table.loading = false
      this.table.loading = false
      this.table.rows = data.rows
      this.table.total = data.total
    },

    //每页数量改变
    sizeChange(limit) {
      this.query.limit = limit
      this.getList()
    },

    //设置form
    setForm(formData = {}) {
      let popDate = JSON.parse(JSON.stringify(formData))
      this.$refs.StoreListPop.onShow(popDate)
    },
    // 设置平台店铺佣金
    setConfigPlatForm(item) {
      this.$refs.StoreListConfigPop.onShow(item)
    },
    // 点击重置
    resetData() {
      this.query =  { search_key: 'title', search_val: '', status: 'ALL', user_id: '', platform_id: 'ALL', mobile: '', account: '', page:1, limit:15 },
      this.getList()
    },

  }
}
</script>

<style lang="scss">
</style>