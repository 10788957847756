<template>
  <div class="s_store_list_pop">
    <el-dialog :close-on-press-escape="false" :title="title" :visible.sync="show" top="10px" width="900px" @before-close="onClose">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <!-- 商城类型 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="商城类型：" label-width="120px">
               <el-select v-model="form.platform_id" disabled placeholder="请选择">
                  <el-option v-for="item in shopTypeList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 公司类型 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="公司类型：" label-width="120px">
              <el-radio-group disabled v-model="form.is_company">
                <el-radio :label="1">个人</el-radio>
                <el-radio :label="2">公司</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 店铺名称 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="店铺名称：" prop="title" label-width="120px">
              <el-row>
                <el-col :span="18">
                  <el-input v-model="form.title" readonly placeholder="请输入店铺名称"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 平台账号 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="平台账号：" prop="account" label-width="120px">
              <el-row>
                <el-col :span="18">
                  <el-input v-model="form.account" readonly placeholder="请输入平台账号"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 店铺首页网址 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="店铺首页网址：" prop="url" label-width="120px">
              <el-row>
                <el-col :span="18">
                  <el-input v-model="form.url" readonly placeholder="请输入店铺首页网址"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 发货地区 -->
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="发货地区:" prop="areaCodeSelected" label-width="120px"  class="area-form-item">
              <area-select v-if="show" :placeholders="['选择省', '选择市', '选择区']" type='all' :level="2" v-model='form.areaCodeSelected' :data="pcaa"></area-select>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 详细地址 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="详细地址：" prop="address" label-width="120px">
              <el-row>
                <el-col :span="18">
                  <el-input readonly v-model="form.address" placeholder="请输入详细地址"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 发货电话 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="发货电话：" prop="mobile" label-width="120px">
              <el-row>
                <el-col :span="18">
                  <el-input readonly v-model="form.mobile" placeholder="请输入发货电话"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 发货联系人 -->
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="发货联系人：" prop="name" label-width="120px">
              <el-row>
                <el-col :span="18">
                  <el-input readonly v-model="form.name" placeholder="请输入发货联系人"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="店铺图片：" prop="logo" label-width="120px">
              <span class="g-red">请上传店铺的管理后台截图</span>
              <div class="s-task-issue-step-upload-box g-flex-align-center g-flex-wrap">
                <!-- 图片 -->
                <div class="s-task-issue-step-upload-img-list g-flex-align-center" v-show="form.logo">
                  <div class="s-task-issue-step-upload-img-list-item">
                    <el-image fit="cover" style="width: 100px; height: 100px" :src="form.logo" :preview-src-list="[form.logo]"> </el-image>
                    <!-- <i class="iconfont iconcuo" @click="deleteImgItem()"></i> -->
                  </div>
                </div>
                <!-- 上传按钮 -->
                <div class="s-task-issue-step-upload g-flex-column g-flex-align-center g-flex-justify-center g-pointer" @click="uploadClick(10)" v-show="!form.logo">
                  <i class="iconfont iconshangchuantupian"></i>
                  <span>上传图片</span>
                </div>
                

                <div class="s-store-list-pop-demo-image-list g-flex">
                  <div class="s-store-list-pop-demo-image-list-item g-flex-column g-flex-align-center" v-for="(item, index) in demoImgList" :key="index" >
                    <el-image fit="cover" style="width: 100px; height: 100px" :src="item.img" :preview-src-list="[item.img]"> </el-image>
                    <span>{{item.name}}</span>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="18">
            <el-form-item label="不通过理由:" label-width="120px">
                <el-input placeholder="请输入不通过理由" type="textarea" v-model="form.remark" :autosize="{ minRows: 4, maxRows: 10 }" maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <task-upload :idNumber="10" :uploadAccept="uploadAccept" @emitFailUpload="emitFailUpload" @emitUploadSuccess="emitSpecialUploadSuccess"/>

      <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="apiUpdateStoreStatusHandel(0)">审核不通过</el-button>
          <el-button type="success" @click="apiUpdateStoreStatusHandel(2)">审核通过</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { pca, pcaa } from 'area-data'; // v5 or higher
  import TaskUpload from '../../taskissue/taskissuedianfu/TaskUpload.vue'
  import { apiSaveShop, apiUpdateStoreStatus } from '@/utils/api.js'
  export default {
    components: { TaskUpload },
    props: {
      shopTypeList: {
        type: Array,
        default() {
          return [ { id: '', title: '' } ]
        }
      }
    },
    data() {
      return {
        pca,
        pcaa,
        uploadAccept: 'image/jpeg,image/jpg,image/png,image/PNG,image/JPG,image/JPEG', //上传的限制类型
        show: false,
        title: '添加店铺',
        rules: {
          title: [
            { required: true, message: '请输入店铺名称', trigger: 'blur' },
          ],
          account: [
            { required: true, message: '请输入平台账号', trigger: 'blur' },
          ],
          address: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
          ],
          name: [
            {  required: true, message: '请输入发货人名称', trigger: 'blur' }
          ],
          mobile: [
            {  required: true, message: '请输入发货电话', trigger: 'blur' }
          ],
          logo: [
            {  required: true, message: '请上传店铺图片', trigger: 'blur' }
          ],
          url: [
            {  required: true, message: '请填写店铺网站首页', trigger: 'blur' }
          ],
          areaCodeSelected: [
            {  required: true, message: '请选择地区', trigger: 'blur' }
          ]
          
        },
        form: {
          platform_id: 1,
          is_company: 1, // is_company 0 未知  1 个人 2公司
          title: '',// 店铺名称
          account: '',  // 平台账号
          url: '', // 店铺网站首页
          province: '', //省
          city: '', //市
          area: '', //区
          address: '', //详细地址
          mobile: '', //发货电话
          name: '', //发货人名称
          logo: '', //店铺图片
          region: '',
          remark: '',
          areaCodeSelected: []
        },
        demoImgList: [
          { img: '/img/taobaoshilitu.jpg', name: '淘宝示例图' },
          { img: '/img/taobaoshilitu.jpg', name: '京东示例图' },
          { img: '/img/taobaoshilitu.jpg', name: '拼多多示例图' },
        ],
        storeTypeList: [
          {
            name:'请选择',
            value: 1
          },
          {
            name:'淘宝/天猫',
            value: 2
          },
          {
            name:'京东',
            value: 3
          },
          {
            name:'拼多多',
            value: 4
          },
        ]
      }
    }, 
    methods: {
      deleteImgItem() {
        this.form.logo = ''
      },
      // 点击提交
      submitClick(formName) {
        if(this.form.areaCodeSelected.length === 0) return this.$message.warning('请填写完整商家信息')
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.apiSaveShopHandel()
          } else {
            this.$message.error('请填写完整商家信息')
            return false;
          }
        });
      },

      // 审核通过2 审核未通过0
      async apiUpdateStoreStatusHandel(status) {
        if(status == 0 && !this.form.remark) return this.$message.error('请输入审核不通过理由')
        let loading = this.$loading()
        const { success, data } = await apiUpdateStoreStatus({ id: this.form.id, remark: this.form.remark, status: status }).catch(loading.close())
        loading.close()
        if(!success) return
        console.log(data)
        this.$message.success(data.msg)
        this.$emit('emitSaveSuccess')
        this.onClose()
      },

      // 修改接口
      async apiSaveShopHandel() {
        if(this.form.region) this.form.region = ''
        this.form.areaCodeSelected.forEach((item, index) => {
          for(let key in item) {
            this.form.region+= item[key] + '-'
            if(index === 0) {
              this.form.province = key
            }else if(index === 1) {
              this.form.city = key
            }else {
              this.form.area = key
            }
          }
        })
        this.form.region = this.form.region.substring(0, this.form.region.length - 1)
        const { success, data } = await apiSaveShop(this.form)
        if(!success) return
        this.$message.success(data.msg)
        this.$emit('emitSaveSuccess')
        this.onClose()
      },
      
      // 展示
      onShow(obj) {
        if(!obj) return this.show = true
        this.title = '审核店铺'
        this.form = obj
        this.form.areaCodeSelected = []
        this.form.areaCodeSelected = [obj.province, obj.city,obj.area]
        this.show = true
        console.log(this.form)
      },

      // 关闭
      onClose() {
        this.show = false
        Object.keys(this.form).forEach((item) => {
          if(item === 'areaCodeSelected') this.form[item] = []
          else this.form[item] = ''
        })
      },
      
      emitFailUpload() {
        this.$message.error('上传失败,请重试')
      },
      
      emitSpecialUploadSuccess(obj) {
        console.log(obj)
        this.form.logo = obj.url
      },

      // 点击上传附加宝贝
      uploadClick(idNumber) {
        this.$nextTick(() => {
         document.getElementById('s-task-upload'+ idNumber).click()
        })
      },
    }
  }
</script>

<style lang="scss">
.s_store_list_pop {
  .el-form {
    .el-form-item {
      &.area-form-item {
        margin-top: 10px;
        .el-form-item__content {
          line-height: 1;
        }
      }
    }

      .s-task-issue-step-upload-box {
        .s-task-issue-step-upload-img-list {
          .s-task-issue-step-upload-img-list-item {
            position: relative;
            border: 1px solid #e6e6e6;
            width: 102px;
            height: 102px;
            margin: 0 10px;
            .iconfont {
              font-size: 20px;
              line-height: 20px;
              background: rgba($color: #000000, $alpha: 0.5);
              position: absolute;
              top: 0;
              right: 0;
              color: $white;
            }
          }
        }
        .s-task-issue-step-upload {
          width: 102px;
          height: 102px;
          border-radius: 5px;
          border: 1px solid #e6e6e6;
          margin-left: 10px;
          .iconfont {
            font-size: 30px;
          }
        }
    }

    .s-store-list-pop-demo-image-list {
      padding-left: 40px;
      .s-store-list-pop-demo-image-list-item {
        padding: 0 20px;
      }
    }
  }


  .s-store-list-pop-btn {
    padding-top: 10px;
    .el-button {
      padding: 10px 40px;
      margin-right: 40px;
    }
  }
}

</style>