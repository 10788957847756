<template>
  <div class="s_store_list_pop">
    <el-dialog :close-on-press-escape="false" :title="title" :visible.sync="show" top="10px" width="900px" @before-close="onClose">
      <el-form ref="ruleForm">
        <!-- 表格 -->
        <el-table stripe size="mini" v-loading="table.loading" :data="table.rows" border>
          <el-table-column label="id">
              <template slot-scope="scope">
                  <span>{{scope.row.id}}</span>
              </template>
          </el-table-column>
          <el-table-column label="平台类型" :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <span>{{ scope.row.platform_id | filtersPlatName(shopTypeList) }}</span>
              </template>
          </el-table-column>
          <el-table-column label="最小金额" :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <span>{{ scope.row.min }}</span>
              </template>
          </el-table-column>
          <el-table-column label="最大金额" :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <span>{{ scope.row.max }}</span>
            </template>
          </el-table-column>

          <el-table-column label="佣金" width="200" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <el-input placeholder="请输入内容" v-model="scope.row.price"></el-input>
              </template>
          </el-table-column>

          <el-table-column label="买家佣金" width="200" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <el-input placeholder="请输入内容" v-model="scope.row.buy_price"></el-input>
              </template>
          </el-table-column>

          <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="apiEditShopPlatConfigHandel(scope.row)">确认修改</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </el-form>


      <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="onClose">取消</el-button>
          <!-- <el-button type="primary" @click="onClose">确认</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { apiGetShopPlatConfig, apiEditShopPlatConfig } from '@/utils/api.js'
  export default {
    props: {
      shopTypeList: {
        type: Array,
        default() {
          return [ { id: '', title: '' } ]
        }
      }
    },
    data() {
      return {
        show: false,
        query: { id: '',  page:1, limit: 15 },
        table:{ loading:false, total:0, rows:[] },
        title: '修改店铺平台佣金',
        form: {
          platform_id: 1,

        }
      }
    }, 
    filters: {
      filtersPlatName(id, list) {
        let obj = list.find(item => item.id == id)
        if(obj) return obj.title
        return ''
      },
    },
    methods: {
      // 展示
      async onShow(obj) {
        if(!obj) return this.show = true
        this.query.id = obj.id
        await this.getList()
        this.show = true
      },

      // 关闭
      onClose() {
        this.query.page = 1
        this.query.limit = 15
        this.query.id = ''
        this.show = false
        // Object.keys(this.form).forEach((item) => {
        //   if(item === 'areaCodeSelected') this.form[item] = []
        //   else this.form[item] = ''
        // })
        // console.log(this.form)
      },
      
      async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        this.table.loading = true
        const { success, data } = await apiGetShopPlatConfig(this.query).catch(() => this.table.loading = false)
        if(!success) return
        this.table.loading = false
        this.table.rows = data.rows
        this.table.total = data.total
      },

      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      },

      // 修改佣金
      async apiEditShopPlatConfigHandel(item) {
        this.$confirm('此操作将会修改该该店铺的平台佣金, 是否继续?', '修改店铺佣金', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.table.loading = true
          const { success, data } =  await apiEditShopPlatConfig(item).catch(() =>  this.table.loading = false)
          this.table.loading = false
          if(!success) return
          this.$message.success(data.msg)
          this.getList()
        }).catch(() => {});
      },
    }
  }
</script>

<style lang="scss">
.s_store_list_pop {
  .el-form {
    .el-form-item {
      &.area-form-item {
        margin-top: 10px;
        .el-form-item__content {
          line-height: 1;
        }
      }
    }

      .s-task-issue-step-upload-box {
        .s-task-issue-step-upload-img-list {
          .s-task-issue-step-upload-img-list-item {
            position: relative;
            border: 1px solid #e6e6e6;
            width: 102px;
            height: 102px;
            margin: 0 10px;
            .iconfont {
              font-size: 20px;
              line-height: 20px;
              background: rgba($color: #000000, $alpha: 0.5);
              position: absolute;
              top: 0;
              right: 0;
              color: $white;
            }
          }
        }
        .s-task-issue-step-upload {
          width: 102px;
          height: 102px;
          border-radius: 5px;
          border: 1px solid #e6e6e6;
          margin-left: 10px;
          .iconfont {
            font-size: 30px;
          }
        }
    }

    .s-store-list-pop-demo-image-list {
      padding-left: 40px;
      .s-store-list-pop-demo-image-list-item {
        padding: 0 20px;
      }
    }
  }


  .s-store-list-pop-btn {
    padding-top: 10px;
    .el-button {
      padding: 10px 40px;
      margin-right: 40px;
    }
  }
}

</style>